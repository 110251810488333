/* global Component, VueRecaptcha, wrapPost */
class complaintPage extends Component {

    static  name() {
        return "complaintPage";
    }

    static componentName() {
        return "complaintPage";
    }

    getComponents() {
        return  {
            'vue-recaptcha': VueRecaptcha
        };
    }

    data(){
        return {
            thanks:false,
            status:"loading",
            useCaptcha:false,
            readyCaptcha:false,
            allowSubmit:false,
            campaignRecord:null,

        };
    }

    getWatch() {
        return {
            'isLoading': function (newValue,oldValue) {
                if(newValue == false)
                    this.getFormData();
            }
        };
    }

    created() {
        return async function () {
            let recaptcha = document.createElement("script");
            recaptcha.setAttribute("src", "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit");
            recaptcha.setAttribute("nonce", "{GOOGLE}");
            document.head.appendChild(recaptcha);
            if(this.$route.params.thanks)
                this.thanks = true;
        };
    }

    mounted() {
        return async function () {
            if(this.$store.getters.getAppState == 'ready'){
                if(!this.$store.state.session){
                    this.$store.dispatch('setRedirectLogin',this.$route.fullPath);
                    this.$router.push({name:'LoginLink'});
                }
                else{
                    if(!this.isLoading)
                        this.getFormData();
                }
            }
        };
    }

    getComputed() {
        return {
            leadCode:function (){
                if(this.$store.getters.getSetting)
                    return this.$store.getters.getSetting.ComplaintFormCode;
                return  null;
            },
            viewEnabled:function (){
                if(this.$store.getters.getSetting)
                    return this.$store.getters.getSetting.ComplaintFormAction;
                return  null;
            },
            apiKey:function (){
                if(this.campaignRecord.RecaptchaKey)
                    return this.campaignRecord.RecaptchaKey;
                else
                    return "6LdgryIUAAAAAELA-bx3qOsRyqaY1XAO7mDblil2";
            }
        };
    }

    submit(e) {
        if(this.useCaptcha && !this.readyCaptcha){
            // eslint-disable-next-line no-alert
            alert(this.tr("Please validate captcha"));
            e.preventDefault();
        }
        this.$store.dispatch('toogleLockScreen', this.tr('Processing'));
        return this.readyCaptcha;
    }

    onCaptchaVerified(recaptchaToken) {
        this.readyCaptcha = true;
    }

    onCaptchaExpired() {
        this.readyCaptcha = false;
        this.$refs.recaptcha.reset();
    }


    async getFormData(){
        try {
            let response = await wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/getRecord`, {
                recordName: "CampaignForm",
                recordId: this.leadCode,
            });
            if (response.data.success) {
                this.campaignRecord = response.data.record;
                this.useCaptcha = response.data.record.UseRecaptcha;
                this.readyCaptcha = !response.data.record.UseRecaptcha;
            }
            this.status = "ready";
        }
        catch (e){
            // eslint-disable-next-line no-console
            console.log("EOOR complaint",e);
        }
    }

    formattedValue(defaultValue){
        if(defaultValue && defaultValue.startsWith("@")) {
            let targetStore = null;
            if (defaultValue.startsWith("@Customer."))
                targetStore = this.$store.getters.getCustomer;
            if (defaultValue.startsWith("@Person."))
                targetStore = this.$store.getters.getSession.fields;

            if (targetStore && defaultValue.search(".") != -1) {
                let targetField = defaultValue.split(".")[1];
                if(targetField == "FullName" && !targetStore[targetField])
                    return `${targetStore["Name"]} ${targetStore["LastName"]}`;
                return targetStore[targetField];
            }
        }
        return defaultValue;
    }

    initComponent(){
        if(this.$store.getters.getAppState == 'ready'){
            if(this.$store.state.session && !this.isLoading) {
                this.getFormData();
            }
            else{
                this.$store.dispatch('setRedirectLogin',this.$route.fullPath);
                this.$router.push({name:'LoginLink'});
            }
        }
    }

    getMethods() {
        return{
            initComponent:this.initComponent,
            getFormData:this.getFormData,
            submit:this.submit,
            onCaptchaExpired:this.onCaptchaExpired,
            onCaptchaVerified:this.onCaptchaVerified,
            formattedValue:this.formattedValue,
        };
    }

    getTemplate() {
        return `<div ref="complain-form" >
                  <headerSectionComponent v-cloak ></headerSectionComponent>
                  <div :class="classContainer" v-if="!isLoading">
                      <div class="row section-main">
                          <template  v-if="viewEnabled">
                            <div class="col-12 col-md-6 offset-md-3" v-if="thanks">
                              <div class="col-12 text-center align-self-center mt-5">
                                <div class="block">
                                    <div class="block-header">
                                        <div class="text-center"><i class="fas fa-check-circle fa-3x mb-3 text-success" ></i></div>
                                        <h5 class="text-center mb-5">{{tr("Thanks for you time")}}.</h5>
                                        <h6 class="text-center">{{tr("Your complaint/suggestion was received")}}.</h6>
                                        <h6 class="text-center mb-5">{{tr("An answer will be sent to you via email")}}</h6>
                                        <router-link to="/" class="btn btn-custom">{{tr("Go to Homepage")}}</router-link>
                                    </div>
                                </div>
                             </div>
                            </div>
                            <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-3" v-else>
                              <form id="lead_form" :action="'/leads/leads_process?uuid='+campaignRecord.Out_uuid" method="post" @submit="submit" validate="validate" v-if="campaignRecord">
                                <h5 class="col-12 mb-4 title underlined-title">{{tr('Help Desk')}}</h5>
                                <input type="hidden" name="LeadSource" :value="campaignRecord.Code">
                                <template v-for="field in campaignRecord.Items">
                                    <div class="form-group">
                                        <label :name="field.LinkedField" v-if="field.Type!='hidden'">{{field.Name}}</label>
                                        <template v-if="field.Type=='textarea'">
                                            <textarea class="form-control" :type="field.Type" :name="field.LinkedField" :value="formattedValue(field.Value)" :placeholder="field.Description" required>
                                            {{formattedValue(field.Value)}}
                                            </textarea>
                                        </template>
                                        <template v-else>
                                            <input class="form-control" :type="field.Type" :name="field.LinkedField" :value="formattedValue(field.Value)" :placeholder="field.Description" required>
                                        </template>
                                    </div>
                                </template>
                                <template v-if="campaignRecord.UseRecaptcha">
                                    <vue-recaptcha
                                      ref="recaptcha"
                                      @verify="onCaptchaVerified"
                                      @expired="onCaptchaExpired"
                                      :sitekey="apiKey" >
                                    </vue-recaptcha>
                                </template>
                                <div class="form-group text-right mt-3">
                                    <router-link to="/profile" class="btn btn-light">
                                        {{tr("Cancel")}}
                                    </router-link>
                                    <button class="btn btn-custom" type="submit" id="send_action" name="action">Enviar</button>
                                </div>
                              </form>
                            </div>
                          </template>
                          <template v-else>
                             <div class="col-12 col-md-8 offset-md-2 text-center align-self-center mt-5">
                                <div class="block">
                                    <div class="block-header">
                                        <div class="text-center"><i class="fas fa-exclamation-triangle fa-3x mb-3" style="color: #666;"></i></div>
                                        <h5 class="text-center mb-5">{{tr("COMPLAINTNOTAVAILABLE")}}.</h5>
                                        <router-link to="/" class="btn btn-custom">{{tr("Back to Homepage")}}</router-link>
                                    </div>
                                </div>
                             </div>
                          </template>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;
    }
}

complaintPage.registerComponent();
